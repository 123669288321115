import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import MDTabNav from '../ui/ui-kit/MDTabNav';
import MDTabLink from '../ui/ui-kit/MDTabLink';
import TeamBanner from '../ui/ui-kit/TeamProfile/TeamBanner';
import StatsContainer from '../ui/ui-kit/TeamProfile/StatsContainer';
import StatsListItem from '../ui/ui-kit/TeamProfile/StatsListItem';
import PassAccuracyGraphic from '../ui/ui-kit/TeamProfile/PassAccuracyGraphic';
import TeamPlay from '../ui/ui-kit/TeamProfile/TeamPlay';
import PreviewLineup from '../ui/ui-kit/PreviewLineup';
import MatchLineupSubsRow from '../ui/ui-kit/MatchLineupSubsRow';
import BackIcon from '../ui/ui-kit/Icons/BackIcon';
import SelectBox from '../ui/ui-kit/SelectBox';
import UpcomingFixtures from '../ui/ui-kit/UpcomingFixtures';
import UpcomingFixtureRow from '../ui/ui-kit/UpcomingFixtureRow';
import PreviousMeetings from '../ui/ui-kit/PreviousMeetings';
import PreviousMeetingsRow from '../ui/ui-kit/PreviousMeetingsRow';
import ResultSummary from '../ui/ui-kit/ResultSummary';
import FixtureScore from '../ui/ui-kit/FixtureScore';
import ResultSummaryRow from '../ui/ui-kit/ResultSummaryRow';
import RegularTable from '../ui/ui-kit/Tables/RegularTable';
import GroupTable from '../ui/ui-kit/Tables/GroupTable';
import Card from '../ui/Card/';
import {
  getTeamTable,
  getTeamFixtures,
  getTeamPosition,
  getTeamsForTable,
  getFixtures,
  getResults,
  getTableCaption,
  getTeamLeagues,
} from '../../utils/teamListings.js';
import { getCompetition } from '../../utils/teamStats';
import { getIconForHighlights } from '../../utils/typeToIconMapper';
import { showLiveForUpcomingMatch } from '../../utils/matchStats';
import { getTeamProfileMetaDescription } from '../../utils/getMetaDescription';
import * as metaDescriptionAction from '../../actions/metaDescription';

class TeamProfileUI extends Component {
  constructor(props) {
    super(props);
    this.getCurrentSelection = this.getCurrentSelection.bind(this);
    this.state = { selectedTab: 'Summary', teamStats: null };
  }

  componentDidMount() {
    const teamName = get(
      this,
      'props.teamSummary["Team Profile"].TeamDisplayName',
      null,
    );
    if (teamName) {
      const metaDescription = getTeamProfileMetaDescription(teamName);
      this.props.metaDescriptionAction &&
        this.props.metaDescriptionAction.setMetaDescription(metaDescription);
    }
  }

  getSquad(squad, key) {
    return squad[key].map((item, index) => {
      return (
        <MatchLineupSubsRow
          key={index}
          className={`${
            index + 1 === squad[key].length
              ? ''
              : 'border-b border-medium-dark-grey bg-solid'
          }`}
          playerNumber={item.JerseyNum}
          playerImage={item.PlayerImageUrl}
          playerName={item.PlayerDisplayName}
          backIcon={<BackIcon className='text-white drop-right' />}
        />
      );
    });
  }

  getTeamStats(stats) {
    if (stats && stats.length) {
      return stats.map((item) => {
        if (item.Title === 'Attack') {
          return (
            <div className='w-full mb-8 xl:mb-16'>
              <StatsContainer
                title={item.Title}
                statsList={item.Statlist.map((listItem, index) => (
                  <StatsListItem
                    key={index}
                    className={`${
                      index + 1 === item.Statlist.length
                        ? ''
                        : 'border-b border-medium-dark-grey bg-solid'
                    }`}
                    displayText={listItem.label}
                    value={listItem.value}
                  />
                ))}
              />
            </div>
          );
        }

        if (item.Title === 'Team Play') {
          return (
            <div className='w-full mb-8 xl:mb-16'>
              <TeamPlay
                passAccuracy={
                  <PassAccuracyGraphic
                    ownHalf={
                      item.Statlist.find((item) => item.label === 'Own Half')
                        .value
                    }
                    oppHalf={
                      item.Statlist.find((item) => item.label === 'Opp. Half')
                        .value
                    }
                  />
                }
                statsList={item.Statlist.map((listItem, index) => (
                  <StatsListItem
                    key={index}
                    className={`${
                      index + 1 === item.Statlist.length
                        ? ''
                        : 'border-b border-medium-dark-grey bg-solid'
                    }`}
                    displayText={listItem.label}
                    value={listItem.value}
                  />
                ))}
              />
            </div>
          );
        } else {
          return (
            <div className='w-full mb-8 xl:mb-16'>
              <StatsContainer
                title={item.Title}
                statsList={item.Statlist.map((listItem, index) => (
                  <StatsListItem
                    key={index}
                    className={`${
                      index + 1 === item.Statlist.length
                        ? ''
                        : 'border-b border-medium-dark-grey bg-solid'
                    }`}
                    displayText={listItem.label}
                    value={listItem.value}
                  />
                ))}
              />
            </div>
          );
        }
      });
    }
    return <span className='text-base text-white'>No Data to Display </span>;
  }

  navigateToMatch(matchId) {
    const { opta } = this.props;
    if (opta && opta.season && opta.competitionId) {
      const competition = getCompetition(Number(opta.competitionId));
      this.props.history.push(
        `/${competition}/play/?season=${opta.season}&competition=${opta.competitionId}&match=${matchId}`,
      );
    }
  }

  checkLive(matchID) {
    const liveData = get(this.props, 'liveMatchData', []);
    let isLive = false;
    if (liveData.length) {
      const matchDetails = liveData.find((match) => match.MatchID === matchID);
      if (!isEmpty(matchDetails)) {
        if (matchDetails.Period) {
          isLive = showLiveForUpcomingMatch(matchDetails.Period);
        }
      }
    }
    return isLive;
  }

  getTeamSummary(teamSummary, title) {
    if (title === 'Club Information') {
      return (
        <div className='w-full mb-8 xl:mb-16'>
          <StatsContainer
            title={title}
            statsList={Object.entries(teamSummary['Club Information']).map(
              (items, index) => {
                return (
                  <StatsListItem
                    key={index}
                    className={`${
                      index + 1 ===
                      Object.entries(teamSummary['Club Information']).length
                        ? ''
                        : 'border-b border-medium-dark-grey bg-solid'
                    }`}
                    displayText={items[0]}
                    value={items[1]}
                  />
                );
              },
            )}
          />
        </div>
      );
    }

    if (title === 'Upcoming Fixtures' && teamSummary[title].length) {
      return (
        <div className='w-full mb-8 xl:mb-16'>
          <UpcomingFixtures
            fixtures={teamSummary[title].map((item, index) => {
              const matchDate = moment
                .utc(get(item, 'MatchDateTimeUTC', ''))
                .local()
                .format('ddd DD MMM YYYY');
              const matchTime = moment
                .utc(get(item, 'MatchDateTimeUTC', ''))
                .local()
                .format('HH:mm');
              const isLive = this.checkLive(item.MatchID);
              return (
                <UpcomingFixtureRow
                  key={index}
                  className={`${
                    index + 1 === teamSummary[title].length
                      ? ''
                      : 'border-b border-medium-dark-grey bg-solid'
                  }`}
                  dateTime={matchDate}
                  matchTime={matchTime}
                  homeTeamName={item.HomeTeamSYMID}
                  homeTeamUrl={item.HomeTeamImageUrl}
                  awayTeamName={item.AwayTeamSYMID}
                  awayTeamUrl={item.AwayTeamImageUrl}
                  navigationHandler={(matchId) => this.navigateToMatch(matchId)}
                  matchID={item.MatchID}
                  showLive={isLive}
                />
              );
            })}
          />
        </div>
      );
    }

    if (title === 'Previous Results' && teamSummary[title].length) {
      return (
        <div className='w-full mb-8 xl:mb-16'>
          <PreviousMeetings
            title='Previous Results'
            items={teamSummary['Previous Results'].map((item, index) => {
              const dateTime = moment
                .utc(get(item, 'MatchDateTime', ''))
                .local()
                .format('ddd DD MMM YYYY');
              return (
                <PreviousMeetingsRow
                  key={index}
                  className={`${
                    index + 1 === teamSummary['Previous Results'].length
                      ? ''
                      : 'border-b border-medium-dark-grey bg-solid'
                  }`}
                  dateTime={dateTime}
                  result={
                    <FixtureScore
                      score1={item.HomeTeamFTScore}
                      score2={item.AwayTeamFTScore}
                      type='time'
                      mini={true}
                      boldLeft={
                        Number(item.HomeTeamFTScore) >
                        Number(item.AwayTeamFTScore)
                          ? true
                          : false
                      }
                      boldRight={
                        Number(item.HomeTeamFTScore) <
                        Number(item.AwayTeamFTScore)
                          ? true
                          : false
                      }
                      showPopover={false}
                    />
                  }
                  homeTeamName={item.HomeTeamSYMID}
                  homeTeamUrl={item.HomeTeamImageUrl}
                  awayTeamName={item.AwayTeamSYMID}
                  awayTeamUrl={item.AwayTeamImageUrl}
                  summary={
                    <ResultSummary
                      resultLeft={item.HomeTeamEvent.map((result, index) => (
                        <ResultSummaryRow
                          key={index}
                          time={result.EventTime}
                          name={result.PlayerDisplayName}
                          icon={getIconForHighlights(result.EventType)}
                        />
                      ))}
                      resultRight={item.AwayTeamEvent.map((result, index) => (
                        <ResultSummaryRow
                          key={index}
                          time={result.EventTime}
                          name={result.PlayerDisplayName}
                          icon={getIconForHighlights(result.EventType)}
                        />
                      ))}
                    />
                  }
                  matchVenue={item.MatchVenue}
                />
              );
            })}
          />
        </div>
      );
    }
  }

  getActivePosition(teamPosition) {
    if (teamPosition === '1') {
      return '1';
    } else if (teamPosition === '20') {
      return '3';
    } else {
      return '2';
    }
  }

  renderTeamTable(tableSummary, fixtures, results) {
    const table = getTeamTable(tableSummary, this.props.teamID);
    const fixture = getTeamFixtures(fixtures, this.props.teamID);
    const result = getTeamFixtures(results, this.props.teamID);
    if (
      table &&
      table.length &&
      ((fixture && fixture.length) || (result && result.length))
    ) {
      return table.map((items, index) => {
        const competitionId = items?.competitionId;
        const fixtureData = getFixtures(fixture, competitionId);
        const fixutureResult =
          fixtureData && fixtureData.length ? fixtureData[0].rows : [];

        // 09-Jan-2024: If competition does not contain table navigation,
        // do not display competition table
        const { opta: optaData } = this.props;
        const optaDataNavigation = optaData?.navigation;
        const tableNavigationExists = optaDataNavigation ?
          find(optaDataNavigation, (data) => data?.path === 'table')
          : true
        ;

        if (tableNavigationExists) {
          if (competitionId === 8) {
            const teamPosition = getTeamPosition(items.rows, this.props.teamID);
            const opta = {
              competitionId: competitionId,
              season: items.season,
              competitionPath: 'epl',
            };
            return (
              <div className='w-full mb-8 xl:mb-16' key={index}>
                <RegularTable
                  hasGradient={true}
                  caption={getTableCaption(competitionId).caption}
                  activePosition={this.getActivePosition(teamPosition.Position)}
                  fullTableLink={getTableCaption(competitionId).link}
                  data={getTeamsForTable(
                    items.rows,
                    Number(teamPosition.Position),
                  )}
                  fullTableData={items && items.rows ? items.rows : []}
                  sectionResults={getResults(result, competitionId)}
                  fixtures={fixutureResult}
                  opta={opta}
                  teams={this.props.teams}
                />
              </div>
            );
          } else {
            const groupName = items.rows.find(
              (e) => e.TeamID === this.props.teamID,
            ).GroupTable;
            const collapsedData = getResults(result, competitionId).filter(
              (result) => result.GroupName === groupName,
            );
            const opta = {
              competitionId: competitionId,
              season: items.season,
              competitionPath: getCompetition(competitionId),
            };

            return (
              <div className='w-full mb-8 xl:mb-16' key={index}>
                <GroupTable
                  hasGradient={true}
                  caption={getTableCaption(competitionId).caption}
                  fullTableLink={getTableCaption(competitionId).link}
                  className='group-table-wrapper'
                  sectionResults={getResults(result, competitionId)}
                  sectionTables={items.rows}
                  fixtures={fixutureResult}
                  groupName={groupName}
                  data={items.rows.filter(
                    (team) => team.GroupTable === groupName,
                  )}
                  collapsedData={{
                    groupName: groupName,
                    data: fixutureResult.filter(
                      (match) =>
                        match.HomeTeamID === this.props.teamID ||
                        match.AwayTeamID === this.props.teamID,
                    ),
                  }}
                  collapsedResults={{groupName, data: collapsedData}}
                  loadMatchHighlights={this.props.loadMatchHighlights}
                  competitionId={competitionId}
                  season={this.props.opta ? this.props.opta.season : ''}
                  onRightIconClick={(matchId) => this.navigateToMatch(matchId)}
                  opta={opta}
                  teams={this.props.teams}
                  type='teamTable'
                  teamID={this.props.teamID}
                />
              </div>
            );
          }
        }

        return null;
      });
    }
  }

  getCurrentSelection(selectedItem) {
    if (this.props.teamStats && this.props.teamStats.length) {
      const filteredTeamStats = this.props.teamStats.find(
        (item) =>
          item.competitionId === Number(selectedItem.value) &&
          item.teamId === this.props.teamID,
      );
      this.setState({
        teamStats:
          filteredTeamStats && filteredTeamStats.rows
            ? filteredTeamStats.rows
            : [],
      });
    }
  }

  renderSelectBox(competitions) {
    const competitionItems = getTeamLeagues(competitions);
    return (
      <div className='w-full md:w-279 mb-8 xl:mb-16'>
        <SelectBox
          items={competitionItems}
          defaultItem={
            competitionItems && competitionItems.length
              ? competitionItems[0]
              : {}
          }
          changed={this.getCurrentSelection}
        />
      </div>
    );
  }

  renderError(errorText) {
    return (
      <div className='w-full my-32 '>
        <span className='text-white text-sm font-MarkProBold '>
          {errorText}
        </span>
      </div>
    );
  }

  renderVideos() {
    const paddingX = 'px-0 sm:px-4 lg:px-4 xl:px-0';
    if (
      this.props.assetCategory &&
      this.props.assetCategory.data &&
      this.props.assetCategory.data.length
    ) {
      // const filteredAsset = this.props.assetCategory.data.filter(asset => asset.)
      return this.props.assetCategory.data.map((assetData, index) => {
        const paddingR =
          (index > 0) & (index / 4 === 0) ? 'pr-0 xl:pr-0' : 'pr-0 xl:pr-30';
        return (
          <Card
            key={index}
            asset={assetData}
            imageBaseUrl={this.props.imageBaseUrl}
            panelCoverSize={this.props.panelCoverSize}
            categoryMapping={this.props.categoryMapping}
            isPremiumUser={this.props.isPremiumUser}
            width='w-full'
            className={`xl:w-25p xl:h-1/4-image  lg:w-1/3 lg:h-1/3-image sm:w-1/2 sm:h-1/2-image w-full pb-8 xl:pb-32 ${paddingX} ${paddingR} `}
            type='teamVideo'
          />
        );
      });
    }

    return (
      <div className='w-full my-32 '>
        <span className='text-white text-sm font-MarkProBold '>
          {' '}
          No related videos found
        </span>
      </div>
    );
  }

  render() {
    let teamStats = [];
    let filteredTeamStats = [];
    const {
      teamSummary,
      tableSummary,
      fixtures,
      results,
      competitions,
      squad,
    } = this.props;
    const competitionItems = getTeamLeagues(competitions);
    const filteredCompetitionItem =
      competitionItems && competitionItems.length && competitionItems[0].value
        ? competitionItems[0].value
        : '';

    if (this.state.teamStats) {
      filteredTeamStats = this.state.teamStats;
    } else if (this.props.teamStats && this.props.teamStats.length) {
      teamStats = this.props.teamStats.find((item) => {
        return item.competitionId === Number(filteredCompetitionItem);
      });
      filteredTeamStats = teamStats && teamStats.rows ? teamStats.rows : [];
    }

    return (
      <article className='h-full w-full flex flex-col team-profile-page'>
        {Object.keys(teamSummary).length ? (
          <TeamBanner
            logo={teamSummary['Team Profile'].TeamImageUrl}
            title={teamSummary['Team Profile'].TeamDisplayName}
            teamColor={teamSummary['Team Profile'].BackColor}
          />
        ) : null}
        <div className='w-full xl:px-132'>
          <MDTabNav>
            <MDTabLink
              label='Summary'
              link='#'
              active={this.state.selectedTab === 'Summary' ? true : false}
              callback={(e) => {
                if (e.target && e.target.id) {
                  e.stopPropagation();
                  this.setState({ selectedTab: e.target.id });
                }
              }}
            />
            <MDTabLink
              label='Videos'
              link='#'
              active={this.state.selectedTab === 'Videos' ? true : false}
              callback={(e) => {
                if (e.target && e.target.id) {
                  e.stopPropagation();
                  this.setState({ selectedTab: e.target.id });
                }
              }}
            />

            <MDTabLink
              label='Stats'
              link='#'
              active={this.state.selectedTab === 'Stats' ? true : false}
              callback={(e) => {
                if (e.target && e.target.id) {
                  e.stopPropagation();
                  this.setState({ selectedTab: e.target.id });
                }
              }}
            />
            <MDTabLink
              label='Squad'
              link='#'
              active={this.state.selectedTab === 'Squad' ? true : false}
              callback={(e) => {
                if (e.target && e.target.id) {
                  e.stopPropagation();
                  this.setState({ selectedTab: e.target.id });
                }
              }}
            />
          </MDTabNav>
          {this.state.selectedTab === 'Videos' && (
            <div className='w-full flex flex-col'>
              <div className='w-full md:w-279 my-8 xl:my-16 px-8 xl:px-0'>
                <SelectBox
                  items={[{ label: 'Most popular', value: 'popular' }]}
                  defaultItem={{ label: 'Most popular', value: 'popular' }}
                  changed={this.getCurrentSelection}
                />
              </div>
              <div className='w-full'>
                <div className='flex flex-wrap px-8 md:px-4 xl:px-0'>
                  {this.renderVideos()}
                </div>
              </div>
            </div>
          )}

          {this.state.selectedTab === 'Summary' && (
            <div className='flex px-7 xl:px-0 flex-wrap py-8 xl:py-16'>
              {this.renderTeamTable(tableSummary, fixtures, results)}
              {!isEmpty(teamSummary) ? (
                <React.Fragment>
                  {this.getTeamSummary(teamSummary, 'Upcoming Fixtures')}
                  {this.getTeamSummary(teamSummary, 'Previous Results')}
                  {/*this.getTeamSummary(teamSummary, "Club Information")*/}
                </React.Fragment>
              ) : (
                this.renderError('No data found')
              )}
            </div>
          )}

          {this.state.selectedTab === 'Stats' && (
            <div className='flex flex-wrap px-7 xl:px-0 flex-col py-8 xl:py-16'>
              {this.renderSelectBox(competitions)}
              {filteredTeamStats.length
                ? this.getTeamStats(filteredTeamStats)
                : this.renderError('No data found')}
            </div>
          )}
          {this.state.selectedTab === 'Squad' && (
            <div className='flex flex-wrap py-8  xl:py-16 px-7 xl:px-0'>
              {!isEmpty(squad) ? (
                <PreviewLineup
                  teamTabs=''
                  goalKeepers={this.getSquad(squad, 'Goalkeepers')}
                  defenders={this.getSquad(squad, 'Defenders')}
                  midFielders={this.getSquad(squad, 'Midfielders')}
                  forwards={this.getSquad(squad, 'Forwards')}
                />
              ) : (
                this.renderError('No data found')
              )}
            </div>
          )}
        </div>
      </article>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    metaDescriptionAction: bindActionCreators(metaDescriptionAction, dispatch),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(TeamProfileUI));
