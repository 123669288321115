import get from 'lodash/get';

export function getOptaConfig(currentPath, navigationList) {
  const paths = currentPath.split('/');

  // current path is in format as /epl/fixture/ ...
  if (!paths || paths.length < 1 || !paths[1]) {
    //wrong currentPath or in home page which is '/' no opta metadata
    return null;
  }

  const competitionPath = paths[1].toLowerCase();
  const currentNavigation = navigationList.find(
    (navigation) => navigation.path === competitionPath,
  );

  const competitionId = get(currentNavigation, 'opta.competitionId', null);
  const competitionCode = get(currentNavigation, 'opta.competitionCode', null);
  const season = get(currentNavigation, 'opta.season', null);
  const optaItems = get(currentNavigation, 'optaItems', null);
  const navigation = get(currentNavigation, 'navigations', []);

  if (competitionId && season) {
    return {
      competitionId,
      season,
      competitionCode,
      competitionPath,
      ...(optaItems && { optaItems }),
      navigation,
    };
  }

  return null;
}
